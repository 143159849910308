import React from "react"

import Container from "./Container"
import { navigatinLinks } from "../links"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { IoLogoYoutube } from "@react-icons/all-files/io5/IoLogoYoutube"
import { IoLogoFacebook } from "@react-icons/all-files/io5/IoLogoFacebook"
import { IoCall } from "@react-icons/all-files/io5/IoCall"
import { IoMail } from "@react-icons/all-files/io5/IoMail"
import { IoLocationSharp } from "@react-icons/all-files/io5/IoLocationSharp"

const Footer = () => {
  const navLinks = navigatinLinks.map(item => (
    <li className="nav__item" key={item.id}>
      <Link to={item.link}>{item.content}</Link>
    </li>
  ))

  return (
    <footer className="footer d-flex">
      <Container _classes='d-flex'>
        {/* Rights */}
        <div className="footer__col col-12 col-sm-6 col-lg-12 col-xl-3 column justify-content-between">
          <div className="topbar__brand">
            <Link to="/" className="brand__link">
              <StaticImage
                src="../images/adept-logo.png"
                alt="Szkoła jazdy Adept"
                placeholder="blurred"
                layout="constrained"
                formats={["auto", "webp"]}
                width={150}
                height={75}
              />
            </Link>
          </div>
          <small>
            &copy; Copyright 2021 Adept <Link to='/polityka'>Polityka prywatności</Link>
            <br />
            Designed {"&"} Made By:{" "}
            <a
              href="https://www.websiteab.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              WebsiteAB
            </a>
          </small>
        </div>

        {/* Locations */}
        <div className="footer__col col-12 col-sm-6 col-lg-4 col-xl-3">
          <h3 className="footer__title">Lokalizacje</h3>
          <div className="footer__item">
            <h4 className="footer__sub-title">
              Ośrodek szkoleniowy i sale wykładowe
            </h4>
            <a
              className="d-flex align-items-start"
              href="https://goo.gl/maps/XTZXHcJXP6hdyQgLA"
              target="_blank"
              rel="noreferrer"
            >
              <IoLocationSharp />
              <span className="d-block">
                ul. Piłsudskiego 23,
                <br />
                43-100 Tychy
              </span>
            </a>
          </div>
          <div className="footer__item">
            <h4 className="footer__sub-title d-flex align-items-center">
              Siedziba firmy
            </h4>
            <a
              className="d-flex align-items-start"
              href="https://goo.gl/maps/hCqJ9wGijEMYQwMB6"
              target="_blank"
              rel="noreferrer"
            >
              <IoLocationSharp />
              <span className="d-block">
                ul. de Gaulle'a 47/19,
                <br />
                43-100 Tychy
              </span>
            </a>
          </div>
        </div>
        {/* Contact */}
        <div className="footer__col col-12 col-sm-6 col-lg-4 col-xl-3">
          <h3 className="footer__title">Skontaktuj się z nami</h3>
          <div className="d-flex flex-wrap">
            <div className="footer__item">
              <h4 className="footer__sub-title">Marta Lesiak - kierownik</h4>
              <a className="d-flex align-items-center" href="tel:+48506631473">
                <IoCall />
                506-631-473
              </a>
            </div>
            <div className="footer__item">
              <h4 className="footer__sub-title">Paweł Lesiak - wykładowca</h4>
              <a className="d-flex align-items-center" href="tel:+48512652662">
                <IoCall />
                512-652-662
              </a>
            </div>
          </div>
          <h4 className="footer__sub-title">Kontakt mailowy</h4>
          <a
            className="d-flex align-items-center"
            href="mailto:kontakt@adept.tychy.pl"
          >
            <IoMail />
            kontakt@adept.tychy.pl
          </a>
        </div>
        {/* Navigation */}
        <div className="footer__col col-12 col-sm-6 col-lg-4 col-xl-3">
          <h3 className="footer__title">Nawigacja</h3>
          <ul className="footer__nav">{navLinks}</ul>
          <h3 className="footer__title">Odwiedź nasze media</h3>
          <a
            className="footer__social"
            aria-label="facebook"
            href="https://www.facebook.com/NaukaJazdyAdept/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoFacebook />
          </a>
          <a
            className="footer__social"
            aria-label="youtube"
            href="https://www.youtube.com/channel/UC-CiV5h4s17aprb-7-CBzKg/featured"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IoLogoYoutube />
          </a>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
